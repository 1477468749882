import { CanvasObject } from '@/lib/types';
import { fabric } from 'fabric-spacerunners';

const CANVAS_PROPS = {
  selection: false,
  disablePinchZoomOnObjects: true,
  enableRetinaScaling: false,
};

const INPAINTING_COLOR = {
  r: 0,
  g: 153,
  b: 255,
  a: 0.6,
};

export const DEFAULT_BRUSH_SIZE = 120;

export const initMaskCanvases = (img: CanvasObject, maskCanvases) => {
  const containerElement = document.getElementById('#editor-area-container');

  const canvasCssHeight = containerElement.clientHeight * 0.8;
  const canvasCssWidth = (img.width / img.height) * canvasCssHeight;

  let canvas = maskCanvases.canvas;

  if (!canvas) {
    canvas = new fabric.Canvas('canvas-mask', {
      width: img.width,
      height: img.height,
      ...CANVAS_PROPS,
    });

    maskCanvases.canvas = canvas;
  }

  img.clone((clone: CanvasObject) => {
    clone.set('left', canvas.width / 2);
    clone.set('top', canvas.height / 2);
    clone.set('originX', 'center');
    clone.set('originY', 'center');

    clone.scaleToWidth(canvas.width);

    canvas.add(clone);

    canvas.renderAll();
  });

  let maskLayer = maskCanvases.maskLayer;

  if (!maskLayer) {
    maskLayer = new fabric.Canvas('canvas-mask-overlay', {
      width: canvas.width,
      height: canvas.height,
      ...CANVAS_PROPS,
    });

    const { r, g, b } = INPAINTING_COLOR;

    maskLayer.set({ backgroundColor: 'transparent' });

    maskLayer.freeDrawingBrush = new fabric.PencilBrush(maskLayer);
    maskLayer.freeDrawingBrush.width = DEFAULT_BRUSH_SIZE;
    maskLayer.freeDrawingBrush.color = `rgba(${r}, ${g}, ${b})`;

    maskLayer.isDrawingMode = true;

    maskCanvases.maskLayer = maskLayer;
  }

  const canvases = [canvas, maskLayer];

  canvases.forEach((c) =>
    c.setDimensions(
      {
        width: `${canvasCssWidth}px`,
        height: `${canvasCssHeight}px`,
      },
      { cssOnly: true }
    )
  );

  maskCanvases.originalImage = img;
};

export const getMaskImage = (maskCanvas): string => {
  const helperCanvas = document.createElement('canvas');

  helperCanvas.width = maskCanvas.width;
  helperCanvas.height = maskCanvas.height;

  const ctx = helperCanvas.getContext('2d');

  ctx.drawImage(maskCanvas.lowerCanvasEl, 0, 0);

  // Get image data from the mask canvas
  const imageData = ctx.getImageData(0, 0, helperCanvas.width, helperCanvas.height);
  const data = imageData.data;

  // Create a new image data array for the mask
  for (let i = 0; i < data.length; i += 4) {
    // If the pixel is the same as inpainting color, set it to white (255). Opacity has to be multiplied by 255
    // Otherwise, set it to black (0)
    const { r, g, b } = INPAINTING_COLOR;

    const isErased = data[i] === r && data[i + 1] === g && data[i + 2] === b;

    data[i] = isErased ? 255 : 0; // Red
    data[i + 1] = isErased ? 255 : 0; // Green
    data[i + 2] = isErased ? 255 : 0; // Blue
    data[i + 3] = 255; // Alpha
  }

  // Put the modified image data back to the mask canvas
  ctx.putImageData(imageData, 0, 0);

  return helperCanvas.toDataURL('image/png');
};
