import { useCallback, useEffect, useRef, useState, Fragment as F, ReactNode } from 'react';

import {
  Box,
  BoxProps,
  HStack,
  Modal,
  ModalOverlay,
  Show,
  useBreakpointValue,
} from '@chakra-ui/react';

import { fabric } from 'fabric-spacerunners';

import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';

import { Design, TextToImageResponse, TemplateColorImage, Template, AiImage } from '@/lib/types';

import CanvasContainer from './components/CanvasContainer';
import GraphicsTools from './graphics-tools';

import QRCodeForm from './components/qr-code/QRCodeForm';

import EditorContainer from './EditorContainer';

import Toolbar from './controls';

import Navbar from './navbar';
import ObjectEditTools from './object-edit-tools';
import './fabric/customisations';

import { GARMENT_IMAGE_MOBILE_WIDTH } from './utils/drawingAreas';
import {
  setBackgroundImageFromUrl,
  addDrawingArea,
  initCanvas,
  removeDrawingArea,
  setBackgroundImage,
} from './utils/canvas-init';

import ToolType from './toolbar/ToolTypes';
import { Hint } from './components/hints/hints';

import { QR_CODE_TYPE } from './fabric';
import { Favorite } from '@/lib';
import Colors from '@/theme/colors';

import ZoomControl from './components/zoom';

import { scaleObjectsForNewCanvas } from './utils/canvas-scaling';
import { debounce, size } from 'lodash';
import Button from '../components/Button';
import { IStyle } from '@space-runners/ablo-ts-sdk/lib/services/style/style.interface';
import { IImageFileToImageRequest } from '@space-runners/ablo-ts-sdk/lib/services/photo-transformer/image-file-to-image-request.interface';
import { IAbloImage } from '@space-runners/ablo-ts-sdk/lib/interfaces/ablo-image.interface';
import { IFontMakerRequest } from '@space-runners/ablo-ts-sdk/lib/services/font-maker/font-maker-request.interface';
import { IImageMakerRequest, IInpaintingRequest } from '@space-runners/ablo-ts-sdk';
import { initMaskCanvases } from './utils/inpainting-mask';

import MaskCanvasContainer from './components/MaskCanvasContainer';
import AiEditing from './toolbar/ai-editing';

const IS_FIRST_TIME_EDITOR_USER = 'IS_FIRST_TIME_EDITOR_USER';

const DEFAULT_CURSOR = 'move';
const PANNING_CURSOR = 'grab';

const RESET_ZOOM_BUTTON_WIDTH = 112;
const ZOOM_CONTROL_WIDTH = 164;
const GAP = 8;

const getCanvasJSON = (canvas) => canvas.toJSON(['aiImage', 'meta', 'perPixelTargetFind']);

const getCanvasStateWithJustObjects = (canvasState) => {
  const stateAsObject = JSON.parse(canvasState);

  delete stateAsObject.clipPath;

  return JSON.stringify(stateAsObject);
};

const getImageForColorAndSide = (
  template: Template,
  templateColorId: string,
  templateSideId: string
) => {
  const variant = (template.colors || []).find((variant) => variant.id === templateColorId);

  return (
    variant &&
    (variant.images as TemplateColorImage[]).find(
      (templateColorImage) => templateColorImage.templateSideId === templateSideId
    )
  );
};

const reloadCanvasFromState = (canvas, stateAsJson, callback?) => {
  canvas.clear();
  canvas.loadFromJSON(stateAsJson, () => {
    canvas.renderAll();

    if (callback) {
      callback();
    }
  });
};

type EditorToolProps = {
  actionCosts?: {
    [key in ToolType]?: number;
  };
  customToolbarContent?: ReactNode;
  design: Design;
  onDesignChange: (design: Design, templateSideId: string, isInitialLoad?: boolean) => void;
  getStyles: () => Promise<IStyle[]>;
  generateImageFromText: (options: IImageMakerRequest) => Promise<TextToImageResponse>;
  generateImageFromImage?: (
    options: IImageFileToImageRequest,
    contentType: string
  ) => Promise<IAbloImage[]>;
  generateImageFromFont?: (options: IFontMakerRequest) => Promise<IAbloImage[]>;
  isQuickSaving?: boolean;
  isDrawingAreaACircle?: boolean;
  removeBackgroundByUrl?: (imageUrl: string) => Promise<string>;
  inpaint?: (options: IInpaintingRequest) => Promise<IAbloImage[]>;
  onBack?: () => void;
  onNext?: () => void;
  onActivateHint?: (hint: Hint) => void;
  addFavorite: (favorite: Favorite) => void;
  favorites: Favorite[];
  removeFavorite: (id: string) => void;
  availableTools?: ToolType[];
  containerProps?: BoxProps;
};

export default function EditorTool({
  actionCosts,
  customToolbarContent,
  design,
  isDrawingAreaACircle,
  isQuickSaving,
  getStyles,
  generateImageFromText,
  generateImageFromImage,
  generateImageFromFont,
  onDesignChange,
  removeBackgroundByUrl,
  inpaint,
  availableTools,
  onBack,
  onNext,
  onActivateHint,
  addFavorite,
  favorites,
  removeFavorite,
  containerProps,
}: EditorToolProps) {
  const canvases = useRef({});

  const maskCanvases = useRef({ canvas: null, maskLayer: null, originalImage: null });

  const state = useRef<string>('');

  const zoomStartScale = useRef(1);
  const panning = useRef({ isHoldingSpace: false, pause: false, lastX: 0, lastY: 0 });

  const containerElementRef = useRef(null);

  const [undoStack, setUndoStack] = useState<string[]>([]);
  const [redoStack, setRedoStack] = useState<string[]>([]);
  const [activeObject, setActiveObject] = useState(null);
  const [selectedSide, setSelectedSide] = useState('');
  const [isEditorToolbarExpanded, setEditorToolbarExpanded] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [activeHint, setActiveHint] = useState<Hint>(null);
  const [isGraphicsPickerVisible, setGraphicsPickerVisible] = useState(false);
  const [isColorPickerVisible, setColorPickerVisible] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);

  const [isAiEditModeActive, setAiEditModeActive] = useState(false);

  const [styles, setStyles] = useState<IStyle[]>([]);

  const { template = {}, templateColorId } = design || {};
  const { id: templateId, sides: templateSides = [] } = template;

  const templateSideId = selectedSide
    ? templateSides.find(({ name }) => name === selectedSide)?.id
    : templateSides[0]?.id;

  const canvas = canvases.current[selectedSide];

  const templateColorImage = getImageForColorAndSide(template, templateColorId, templateSideId);

  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false });
  const isPhone = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const saveState = useCallback(() => {
    setRedoStack([]);

    // Initial call won't have a state
    if (state.current) setUndoStack([...undoStack, state.current]);

    const json = getCanvasJSON(canvas);

    state.current = JSON.stringify(json);

    const { sides } = design;

    const [textObjects, imageObjects] = partition(canvas._objects, ({ text }) => !!text);

    const newSides = sides.map((side) => {
      if (side.templateSideId === templateSideId) {
        return {
          ...side,
          canvas,
          canvasState: state.current,
          hasGraphics: imageObjects.length > 0,
          hasText: textObjects.length > 0,
        };
      }

      return side;
    });

    onDesignChange(
      {
        ...design,
        sides: newSides,
      },
      templateSideId
    );
  }, [canvas, design, templateSideId, onDesignChange, undoStack]);

  const isEditing =
    canvas?.getActiveObject() && isMobile ? canvas.getActiveObject().isEditing : false;

  useEffect(() => {
    const loadStyles = async () => {
      const styles = await getStyles();

      setStyles(styles);
    };

    loadStyles();
  }, [getStyles]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    const shouldHideNavbars = isEditing || isEditorToolbarExpanded;

    const dashboardNavbar = document.getElementById('ablo-dashboard-navbar');

    if (dashboardNavbar) {
      dashboardNavbar.style.display = shouldHideNavbars ? 'none' : 'flex';
    }

    const abloNavbar = document.getElementById('ablo-navbar');

    if (abloNavbar) {
      abloNavbar.style.display = shouldHideNavbars ? 'none' : 'block';
    }
  }, [isEditing, isEditorToolbarExpanded, isMobile]);

  useEffect(() => {
    if (!containerElementRef.current || isPhone) return;
    const canvasObjects = canvases.current;

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        const { sides: templateSides } = template;
        if (isEmpty(templateSides)) {
          return;
        }

        templateSides.forEach((templateSide) => {
          const canvas = canvasObjects[templateSide.name];

          const { clipPath } = canvas;

          initCanvas(templateSide, isPhone, canvas, isDrawingAreaACircle);

          scaleObjectsForNewCanvas(canvas, clipPath, canvas._objects);
        });

        if (canvas?.backgroundImage) {
          setBackgroundImage(canvas, canvas?.backgroundImage, isPhone, isDrawingAreaACircle);
        }
      }, 100)
    );

    resizeObserver.observe(containerElementRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isPhone, template, canvas, isDrawingAreaACircle]);

  useEffect(() => {
    const { defaultZoom, sides: templateSides } = template;
    const canvasObjects = canvases.current;

    if (isEmpty(templateSides)) {
      return;
    }

    templateSides.forEach((templateSide, index) => {
      const { initialCanvasState } = templateSide;

      const canvas = initCanvas(templateSide, isPhone);

      canvasObjects[templateSide.name] = canvas;

      const { canvasState } =
        design.sides.find(({ templateSideId }) => templateSide.id === templateSideId) || {};

      let defaultState = canvasState || initialCanvasState;

      if (defaultState) {
        const defaultStateAsObject = JSON.parse(defaultState);

        const originalClipPath = defaultStateAsObject.clipPath;

        delete defaultStateAsObject.clipPath;

        defaultState = JSON.stringify(defaultStateAsObject);

        if (index === 0) {
          state.current = defaultState;
        }

        reloadCanvasFromState(canvas, defaultState, () => {
          scaleObjectsForNewCanvas(canvas, originalClipPath, defaultStateAsObject.objects);

          setBackgroundImageFromUrl(canvas, templateColorImage.url, isPhone, isDrawingAreaACircle);
        });
      } else {
        state.current = JSON.stringify(canvas);

        setBackgroundImageFromUrl(canvas, templateColorImage.url, isPhone, isDrawingAreaACircle);
      }

      canvas.on('mouse:up', function (e) {
        if (document?.activeElement && document?.activeElement) {
          (document?.activeElement as HTMLInputElement).blur();
        }

        if (!e.target) {
          setEditorToolbarExpanded(false);
        }

        if (e.target?.selectable === false) {
          return;
        }

        setActiveObject(e.target ? { ...e.target } : null);

        if (e.target) {
          canvas.setActiveObject(e.target);

          addDrawingArea(canvas);
        } else if (canvas?.discardActiveObject) {
          canvas.discardActiveObject().renderAll();

          removeDrawingArea(canvas);
        }

        canvas.renderAll();

        panning.current = { ...panning.current, pause: !!e.target };
      });
    });

    const newSides = design.sides.map((side) => {
      const { templateSide } = side;

      const canvas = canvasObjects[templateSide?.name];

      return {
        ...side,
        canvas,
      };
    });

    setSelectedSide(templateSides[0].name);

    if (defaultZoom && defaultZoom > 0) {
      const newZoomLevel = defaultZoom / 10 + 1;

      const canvas = canvasObjects[templateSides[0].name];

      canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), newZoomLevel);

      setZoomLevel(defaultZoom);
    } else if (zoomLevel > 0) {
      setZoomLevel(0);
    }

    setTimeout(() => {
      onDesignChange(
        {
          ...design,
          sides: newSides,
        },
        templateSideId,
        true
      );
    }, 1000);

    if (!localStorage.getItem(IS_FIRST_TIME_EDITOR_USER)) {
      if (size(template.sides) > 1) {
        setActiveHint(Hint.SIDE_PICKER);
      } else {
        setActiveHint(Hint.CUSTOM_QR_CODE);
      }

      localStorage.setItem(IS_FIRST_TIME_EDITOR_USER, 'true');
    }

    return () => {
      templateSides.forEach((templateSide) => {
        const canvas = canvasObjects[templateSide.name];

        if (canvas) {
          canvas.dispose();
        }
      });

      canvases.current = {};
    };
  }, [templateId]);

  useEffect(() => {
    if (!canvas) return;

    function handleClickOutside(e) {
      // On pressing "Next" in the navbar we have to deselect the active object or its handles are seen in the export
      const navbar = document.getElementById('ablo-navbar');

      if (navbar && navbar.contains(e.target)) {
        canvas.discardActiveObject().renderAll();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    function handleKeyUp(e: KeyboardEvent) {
      panning.current = { ...panning.current, isHoldingSpace: false };

      if (!isMobile) {
        canvas.hoverCursor = DEFAULT_CURSOR;
        canvas.moveCursor = DEFAULT_CURSOR;

        fabric.Object.prototype.selectable = true;
      }

      if (e.key == 'Delete' || e.code == 'Delete' || e.key == 'Backspace') {
        const activeObject = canvas.getActiveObject();

        if (!activeObject || activeObject.isEditing || activeObject.type === QR_CODE_TYPE) {
          return;
        }

        canvas.remove(activeObject);

        setActiveObject(null);

        saveState();
      }
    }

    function handleKeyDown(e: KeyboardEvent) {
      const target = e.target as Node;

      if (e.code === 'Space' && target?.nodeName !== 'TEXTAREA') {
        panning.current = { ...panning.current, isHoldingSpace: true };

        if (!isMobile) {
          canvas.hoverCursor = PANNING_CURSOR;
          canvas.moveCursor = PANNING_CURSOR;

          canvas.discardActiveObject();

          fabric.Object.prototype.selectable = false;
        }
      }

      const STEP = 10;

      const activeObject = canvas.getActiveObject();

      if (!activeObject || !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        return;
      }

      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        activeObject.set({ left: activeObject.left - STEP });
      } else if (e.key === 'ArrowUp') {
        activeObject.set({ top: activeObject.top - STEP });
      } else if (e.key === 'ArrowRight') {
        activeObject.set({ left: activeObject.left + STEP });
      } else if (e.key === 'ArrowDown') {
        activeObject.set({ top: activeObject.top + STEP });
      }

      canvas.renderAll();

      saveState();
    }

    // Bind the event listener
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);

    canvas.on('object:modified', () => {
      saveState();
    });

    canvas.on('erasing:end', () => {
      saveState();
    });

    const handlePan = (e) => {
      const currentX = e.e.layerX || e.self.x;
      const currentY = e.e.layerY || e.self.y;

      const { isHoldingSpace, pause } = panning.current;

      const isDraggingAllowed = isMobile ? !pause : isHoldingSpace;

      if (!isDraggingAllowed || !currentX || !currentY) {
        return;
      }

      const { lastX = 0, lastY = 0 } = panning.current;

      const xChange = currentX - lastX;
      const yChange = currentY - lastY;

      const DRAG_THRESHOLD = 50;
      const DRAG_DELTA_SCALING_FACTOR = 5;

      if (
        Math.abs(currentX - lastX) <= DRAG_THRESHOLD &&
        Math.abs(currentY - lastY) <= DRAG_THRESHOLD
      ) {
        const delta = new fabric.Point(
          xChange * DRAG_DELTA_SCALING_FACTOR,
          yChange * DRAG_DELTA_SCALING_FACTOR
        );

        if (canvas.getZoom() > 1) {
          canvas.relativePan(delta);
        }
      }

      panning.current = { ...panning.current, lastX: currentX, lastY: currentY };
    };

    canvas.on({
      'touch:gesture': function (e) {
        if (e.e.touches && e.e.touches.length == 2) {
          if (canvas.getActiveObject()) {
            panning.current = { ...panning.current, pause: false };
          }

          const point = new fabric.Point(
            (e.self.x / GARMENT_IMAGE_MOBILE_WIDTH) * canvas.width,
            (e.self.y / GARMENT_IMAGE_MOBILE_WIDTH) * canvas.height
          );

          if (canvas.getActiveObject()) {
            handlePan(e);
          }

          if (e.self.state == 'start') {
            zoomStartScale.current = canvas.getZoom();
          }

          const delta = zoomStartScale.current * e.self.scale;

          canvas.zoomToPoint(point, delta);

          if (canvas.getZoom() < 0.5) {
            canvas.zoomToPoint(point, 0.5);
          }
        }
      },

      'selection:cleared': function () {
        panning.current = { ...panning.current, pause: false };
      },

      'touch:drag': function (e) {
        if (isMobile && canvas.getActiveObject()) {
          return;
        }
        handlePan(e);
      },

      'touch:longpress': function (e) {
        if (isMobile && e.target?.selectable) {
          canvas.setActiveObject(e.target);

          canvas.renderAll();

          setActiveObject(e.target);

          panning.current = { ...panning.current, pause: true };
        } else {
          handleResetZoom();
        }
      },
    });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);

      if (canvas) {
        canvas.off('object:modified');
        canvas.off('erasing:end');
      }
    };
  }, [canvas, saveState]);

  const handleResetZoom = () => {
    canvas.setZoom(1);
    canvas.absolutePan(new fabric.Point(0, 0));

    setZoomLevel(0);
  };

  const handleUndo = () => {
    setRedoStack([...redoStack, state.current]);

    state.current = undoStack.pop();

    setUndoStack(undoStack);

    reloadCanvasFromState(canvas, getCanvasStateWithJustObjects(state.current), () => {
      setBackgroundImageFromUrl(canvas, templateColorImage.url, isPhone, isDrawingAreaACircle);
    });
  };

  const handleRedo = () => {
    setUndoStack([...undoStack, state.current]);

    state.current = redoStack.pop();

    setRedoStack(redoStack);

    reloadCanvasFromState(canvas, getCanvasStateWithJustObjects(state.current), () => {
      setBackgroundImageFromUrl(canvas, templateColorImage.url, isPhone, isDrawingAreaACircle);
    });
  };

  const handleStartAiEditing = () => {
    const activeObject = canvas.getActiveObject();

    setAiEditModeActive(true);

    initMaskCanvases(activeObject, maskCanvases.current);
  };

  const handleClick = (e) => {
    if (!e.target || e.target.className.includes('canvas')) return;

    setActiveObject(null);
    canvas.discardActiveObject().renderAll();
  };

  const handleZoomLevelChange = (zoomLevel) => {
    const newZoomLevel = zoomLevel / 10 + 1;

    const activeObject = canvas.getActiveObject();

    if (activeObject) {
      const { left, top } = activeObject;

      canvas.setZoom(1);

      const viewportWidth = canvas.width / newZoomLevel;
      const viewportHeight = canvas.height / newZoomLevel;
      const x = left - viewportWidth / 2;
      const y = top - viewportHeight / 2;

      canvas.absolutePan({ x, y });

      canvas.setZoom(newZoomLevel);
    } else {
      canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), newZoomLevel);
    }

    if (newZoomLevel === 1) {
      canvas.absolutePan(new fabric.Point(0, 0));
    }

    setZoomLevel(zoomLevel);
  };

  const handleAddText = (defaultProps) => {
    const { top = 0, left = 0, width, height } = canvas.clipPath || canvas;

    const aiImage = canvas._objects.find(({ aiImage }) => aiImage);

    const maxTextOffset = height - 80;

    const textObject = {
      ...defaultProps,
      fill: Colors.gray,
      editingBorderColor: 'red',
      cursorWidth: 10,
      left: left + width / 2,
      top:
        top +
        (aiImage
          ? Math.min(aiImage.aCoords.tl.y + aiImage.height, maxTextOffset)
          : height / 2 - 20),
    };

    const text = new fabric.IText(textObject.text, textObject);

    text.enterEditing();
    text.hiddenTextarea.focus();

    canvas.add(text);
    canvas.setActiveObject(text);

    setActiveObject(textObject);

    setSelectedTool('color');
  };

  const handleQRCodeLinkChange = (link) => {
    canvas.getActiveObject().set({ dirty: true, text: link });

    canvas.renderAll();

    setActiveObject({ ...activeObject, text: link });

    saveState();
  };

  const handleCrop = (image) => {
    canvas.setActiveObject(image).renderAll();

    setActiveObject(image);

    saveState();
  };

  const handlePreviewImageSelected = (image: IAbloImage) => {
    addAiImageToCanvas({ ...image });

    saveState();

    setActiveObject(canvas.getActiveObject());

    setEditorToolbarExpanded(false);
  };

  const handleImageUpdate = (aiImage: AiImage, preloadedImage: string = null) => {
    const oldImage = canvas.getActiveObject();

    canvas.remove(canvas.getActiveObject());

    const { left, top, scaleX, scaleY } = oldImage;

    const options = { left, top, scaleX, scaleY };

    if (preloadedImage) {
      addImageToCanvas(preloadedImage, { ...options, aiImage });
    } else {
      addAiImageToCanvas(aiImage, { left, top, scaleX, scaleY });
    }

    saveState();
  };

  const handleAiEditedImageSelected = (image: AiImage) => {
    const { originalImage } = maskCanvases.current;

    canvas.remove(originalImage);

    const { left, top, scaleX, scaleY } = originalImage;

    addAiImageToCanvas(image, { left, top, scaleX, scaleY });

    setAiEditModeActive(false);
    setEditorToolbarExpanded(false);

    saveState();
  };

  const addAiImageToCanvas = (image, options = {}) => {
    fabric.Image.fromURL(
      image.url,
      (img) => {
        addImageToCanvas(img, { aiImage: image, ...options });
      },
      { crossOrigin: 'anonymous' }
    );
  };

  const addImageToCanvas = (img, options = {}) => {
    const {
      left = 0,
      top = 0,
      width: canvasWidth,
      height: canvasHeight,
    } = canvas.clipPath || canvas;

    if (img.width >= img.height) {
      img.scaleToWidth(canvasWidth);
    } else {
      img.scaleToHeight(canvasHeight);
    }

    const resizeFilter = new fabric.Image.filters.Resize({
      resizeType: 'sliceHack',
    });

    img.filters.push(resizeFilter);
    img.applyFilters();

    img.set({
      left: left + canvasWidth / 2,
      top: top + canvasHeight / 2,
      originX: 'center',
      originY: 'center',
      centeredScaling: true,
      ...options,
    });

    img.crossOrigin = 'anonymous';

    canvas.add(img);

    setActiveObject(img);

    canvas.setActiveObject(img).renderAll();

    saveState();
  };

  const handleSelectedSide = (sideName: string) => {
    canvas.discardActiveObject().renderAll();

    setEditorToolbarExpanded(false);
    setActiveObject(null);
    setSelectedSide(sideName);
    setRedoStack([]);
    setUndoStack([]);

    state.current = JSON.stringify(canvases.current[sideName]);

    const { id } = template.sides.find(({ name }) => name === sideName);

    const image = getImageForColorAndSide(template, templateColorId, id);

    setBackgroundImageFromUrl(
      canvases.current[sideName],
      image.url,
      isMobile,
      isDrawingAreaACircle
    );
  };

  const handleColorChange = (templateColorId) => {
    onDesignChange(
      {
        ...design,
        templateColorId,
      },
      templateSideId
    );

    const image = getImageForColorAndSide(template, templateColorId, templateSideId);

    setBackgroundImageFromUrl(canvas, image.url, isMobile, isDrawingAreaACircle);
  };

  const handleObjectAdded = (object) => {
    setActiveObject(object);

    canvas.setActiveObject(object).renderAll();

    saveState();
  };

  const undoHandler = isEmpty(undoStack) ? null : handleUndo;
  const redoHandler = isEmpty(redoStack) ? null : handleRedo;

  const showHint = isEmpty(filter(canvas?._objects, (object) => !object.excludeFromExport));

  return (
    <Box display="block" h="100%" left={0} top={0} {...containerProps}>
      {activeHint ? (
        <Modal isOpen={activeHint !== null} onClose={() => {}}>
          <ModalOverlay zIndex={3} />
        </Modal>
      ) : null}
      {!isMobile || (!isEditing && !isEditorToolbarExpanded) ? (
        <Navbar
          activeHint={activeHint}
          onNextHint={setActiveHint}
          onBack={onBack}
          onNext={onNext}
          onSelectedSide={handleSelectedSide}
          selectedSide={selectedSide}
          template={template}
          isQuickSaving={isQuickSaving}
        />
      ) : null}
      <EditorContainer
        actionCosts={actionCosts}
        onGeneratedImageSelected={handlePreviewImageSelected}
        isEditing={isEditing}
        isEditorToolbarExpanded={isEditorToolbarExpanded}
        onChangeEditorToolbarExpanded={setEditorToolbarExpanded}
        generateImageFromText={generateImageFromText}
        generateImageFromImage={generateImageFromImage}
        generateImageFromFont={generateImageFromFont}
        styles={styles}
        customToolbarContent={
          customToolbarContent ||
          (activeObject?.type === QR_CODE_TYPE ? (
            <QRCodeForm
              link={activeObject.text}
              onChange={handleQRCodeLinkChange}
              onNext={() => {
                canvas.discardActiveObject().renderAll();
                setActiveObject(null);
                setEditorToolbarExpanded(false);
              }}
            />
          ) : isAiEditModeActive ? (
            <AiEditing
              maskCanvases={maskCanvases.current}
              addFavorite={addFavorite}
              favorites={favorites}
              removeFavorite={removeFavorite}
              inpaint={inpaint}
              isMobile={isMobile}
              onBack={() => setAiEditModeActive(false)}
              onGeneratedImageSelected={handleAiEditedImageSelected}
            />
          ) : null)
        }
        availableTools={availableTools}
        addFavorite={addFavorite}
        favorites={favorites}
        removeFavorite={removeFavorite}
        template={template}
        isAiModeActive={isAiEditModeActive}
      >
        <F>
          {isEditorToolbarExpanded && isMobile ? null : activeObject ? (
            <ObjectEditTools
              activeObject={activeObject}
              canvas={canvas}
              maskCanvases={maskCanvases.current}
              onStateChange={saveState}
              onCrop={handleCrop}
              onSetActiveObject={setActiveObject}
              onImageUpdate={handleImageUpdate}
              onUndo={undoHandler}
              onRedo={redoHandler}
              removeBackgroundByUrl={removeBackgroundByUrl}
              onAiEdit={() => handleStartAiEditing()}
              isDrawingMask={isAiEditModeActive}
              selectedTool={selectedTool}
              onChangeSelectedTool={setSelectedTool}
            />
          ) : (
            <Toolbar
              activeHint={activeHint}
              onNextHint={setActiveHint}
              canvas={canvas}
              selectedVariantId={templateColorId}
              onObjectAdded={handleObjectAdded}
              onAddText={handleAddText}
              onToggleColorPicker={() => {
                setColorPickerVisible(!isColorPickerVisible);
                setGraphicsPickerVisible(false);
              }}
              onToggleGraphicsPicker={() => {
                setGraphicsPickerVisible(!isGraphicsPickerVisible);
                setColorPickerVisible(false);
              }}
              onUndo={undoHandler}
              onRedo={redoHandler}
              template={template}
            />
          )}

          <Box
            id="#editor-area-container"
            flex={1}
            position="relative"
            overflow={isAiEditModeActive ? 'hidden' : 'auto'}
          >
            <Box position="absolute" left="22px" top="12px" zIndex={4}>
              <GraphicsTools
                isColorPickerVisible={isColorPickerVisible}
                isGraphicsPickerVisible={isGraphicsPickerVisible}
                onCloseColorPicker={() => setColorPickerVisible(false)}
                onCloseGraphicsPicker={() => setGraphicsPickerVisible(false)}
                canvas={canvas}
                selectedVariantId={templateColorId}
                onSelectedVariant={handleColorChange}
                onObjectAdded={handleObjectAdded}
                template={template}
              />
            </Box>
            <Box
              bg="#2D3748"
              position="relative"
              h="100%"
              display={isAiEditModeActive ? 'block' : 'none'}
            >
              <MaskCanvasContainer id="canvas-mask"></MaskCanvasContainer>
              <MaskCanvasContainer id="canvas-mask-overlay"></MaskCanvasContainer>
            </Box>
            <Box
              alignItems="center"
              bgColor={isMobile && isEditorToolbarExpanded ? '#CBD5E0' : '#F9F9F7'}
              display="flex"
              flexDirection="column"
              ref={containerElementRef}
              id="#editor-container"
              h="100%"
              onClick={handleClick}
              overflowY={activeHint === Hint.PINCH_ZOOM ? 'visible' : 'auto'}
              paddingTop={isEditing && isPhone ? '100px' : 0}
              position="relative"
              visibility={isAiEditModeActive ? 'hidden' : 'visible'}
            >
              {templateSides.map(({ name: sideName }, index) => (
                <Box
                  id={`#canvas-container-${sideName}`}
                  display={selectedSide === sideName ? 'block' : 'none'}
                  key={index}
                  h="100%"
                  position="relative"
                  userSelect="none"
                  overflow="hidden"
                  w="100%"
                >
                  <CanvasContainer
                    activeHint={activeHint}
                    onNextHint={(hint) => {
                      if (hint === Hint.FINISH_AND_SHARE && !isMobile) {
                        if (onActivateHint) {
                          onActivateHint(Hint.FINISH_AND_SHARE);
                        }

                        setActiveHint(null);

                        return;
                      }

                      setActiveHint(hint);
                    }}
                    canvas={canvases.current[sideName]}
                    template={template}
                    showHint={showHint}
                    side={sideName}
                    onHintClick={() => {
                      setEditorToolbarExpanded(true);
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          {!isAiEditModeActive ? (
            <Show above="md">
              <HStack
                position="absolute"
                left={{
                  base: `calc(50% - ${RESET_ZOOM_BUTTON_WIDTH / 2}px)`,
                  lg: `calc(50% - ${ZOOM_CONTROL_WIDTH / 2}px)`,
                }}
                bottom={{ base: '105px', md: '115px', lg: '10px' }}
                gap={`${GAP}px`}
                margin="0 auto"
              >
                {isMobile ? (
                  <Button
                    variant="outlined"
                    border="none"
                    fontSize="sm"
                    onClick={handleResetZoom}
                    right="20px"
                    position={{ base: 'static', lg: 'relative' }}
                    w={`${RESET_ZOOM_BUTTON_WIDTH}px`}
                  >
                    Reset Zoom
                  </Button>
                ) : (
                  <ZoomControl
                    activeHint={activeHint}
                    onNextHint={() => {
                      if (onActivateHint) {
                        onActivateHint(Hint.FINISH_AND_SHARE);
                      }

                      setActiveHint(null);
                    }}
                    zoomLevel={zoomLevel}
                    onZoomLevelChange={handleZoomLevelChange}
                    width={ZOOM_CONTROL_WIDTH}
                  />
                )}
              </HStack>
            </Show>
          ) : null}
        </F>
      </EditorContainer>
    </Box>
  );
}
