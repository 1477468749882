import { useHistory, useLocation } from 'react-router-dom';
import GuestPageContainer from '@/layouts/GuestPageContainer';

import { useState } from 'react';

import Home from './Home';
import { Show } from '@chakra-ui/react';
import ButtonBack from '@/components/navbar/ButtonBack';

export default function HomeGuest() {
  const [isSignInModalVisible, setSignInModalVisible] = useState(false);

  const [wantsToRemix, setWantsToRemix] = useState(false);
  const [wantsToStartDesigning, setWantsToStartDesigning] = useState(false);
  const [designIdToAddToCart, setDesignIdToAddToCart] = useState<string>(null);

  const history = useHistory();

  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const handleCancelSignIn = () => {
    setSignInModalVisible(false);
  };

  const handleSignedIn = () => {
    setSignInModalVisible(false);

    let destination = '/';

    if (wantsToRemix) {
      destination = `?remix=true`;
    } else if (wantsToStartDesigning) {
      destination = '?startDesigning=true';
    } else if (designIdToAddToCart) {
      destination = `?designToAddToCart=${designIdToAddToCart}`;
    }

    window.location.href = destination;
  };

  const handleSignInToRemix = () => {
    setWantsToRemix(true);

    setSignInModalVisible(true);
  };

  const handleSignInToStartDesigning = () => {
    setWantsToStartDesigning(true);

    setSignInModalVisible(true);
  };

  const handleSignInToAddToCart = (designId: string) => {
    setDesignIdToAddToCart(designId);

    setSignInModalVisible(true);
  };

  return (
    <GuestPageContainer
      showSignInModal={isSignInModalVisible}
      onCancelSignIn={handleCancelSignIn}
      onSignedIn={handleSignedIn}
      rightSideNavbarContent={
        queryParams.get('collectionId') ? (
          <Show above="md">
            <ButtonBack onClick={() => history.replace('/')} />
          </Show>
        ) : null
      }
      pageName="Home"
    >
      <Home
        onSignInToRemix={handleSignInToRemix}
        onSignInToStartDesigning={handleSignInToStartDesigning}
        onSignInToAddToCart={handleSignInToAddToCart}
      />
    </GuestPageContainer>
  );
}
