import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Flex, HStack, Show, SimpleGrid, Text } from '@chakra-ui/react';

import { Design } from '@/lib';

import { getDesigns } from '@/api/designs';
import { User } from '@/components/types';
import { isEmpty, uniqBy } from 'lodash';

import { useDebouncedValue } from '@/hooks/useDebouncedValue';
import { useHistory, useLocation } from 'react-router-dom';
import Checkbox from '@/components/ui/checkbox';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import ProductCard from './ProductCard';
import Search from '@/components/search';
import EmptyState from './EmptyState';
import CardsDisplayToggle from './cards-display-toggle';
import { trackEvent } from '@/analytics';
import { useCategories } from '@/api/templates';

interface ProductListProps {
  categoryId: string;
  maxItems?: number;
  user?: User;
}

interface MyDesign extends Design {
  isMyDesign: true;
}

export default function ProductList({ maxItems, categoryId, user }: ProductListProps) {
  const [designs, setDesigns] = useState<Design[]>([]);
  const [total, setTotal] = useState<number>(0);

  const [hasAllItems, setHasAllItems] = useState(false);

  const [isLoadingNextPage, setLoadingNextPage] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [isShowingMyDesigns, setIsShowingMyDesigns] = useState(false);
  const [myDesigns, setMyDesigns] = useState<MyDesign[]>([]);

  const [isLargeCardView, setLargeCardView] = useState(true);

  const observer = useRef<IntersectionObserver>(null);
  const highlightedDesignRef = useRef(null);

  const itemsPerPage = 8;

  const { search: searchParams } = useLocation();

  const { data: categories = [] } = useCategories();

  const queryParams = new URLSearchParams(searchParams);

  const [searchTerm, setSearchTerm] = useState(queryParams.get('search') || undefined);

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);

  const page = queryParams.get('page');
  const highlightedDesignId = queryParams.get('highlightedDesignId');

  const history = useHistory();

  useEffect(() => {
    if (debouncedSearchTerm === undefined) {
      return;
    }

    setCurrentPage(1);

    setHasAllItems(false);

    setLoadingNextPage(true);

    const searchParams = new URLSearchParams();

    searchParams.append('page', '1');
    searchParams.append('search', `${debouncedSearchTerm}`);
    searchParams.append('categoryId', `${categoryId}`);

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    getDesigns({
      isPublished: true,
      categoryId,
      take: maxItems || itemsPerPage,
      skip: 0,
      search: debouncedSearchTerm,
    }).then(({ designs, total }) => {
      setDesigns(designs);
      setTotal(total);

      trackEvent('search_interaction', {
        search_term: debouncedSearchTerm,
        result_count: total,
        search_location: 'products_page',
      });

      if (maxItems || designs.length < itemsPerPage) {
        setHasAllItems(true);
      }

      setLoadingNextPage(false);
    });
  }, [debouncedSearchTerm, setCurrentPage]);

  useEffect(() => {
    if (maxItems) {
      return;
    }

    setLoadingNextPage(true);

    const initialPage = parseInt(page, 10) || 1;

    const baseParams = {
      isPublished: true,
      categoryId,
      take: initialPage * itemsPerPage,
      skip: 0,
    };

    const loadInitialData = async () => {
      let myDesignsResponse = { designs: [] };

      if (user?.id) {
        myDesignsResponse = await getDesigns({
          isPublished: true,
          categoryId,
          userId: user.id,
          take: 1000 * 1000,
          skip: 0,
        });
      }

      const myDesigns = myDesignsResponse.designs.map((design) => ({
        ...design,
        isMyDesign: true,
      }));

      setMyDesigns(myDesigns);

      getDesigns(searchTerm ? { ...baseParams, search: searchTerm } : baseParams).then(
        ({ total, designs }) => {
          setDesigns(designs);
          setTotal(total);

          trackEvent('collection_page_view', {
            category_filter: categories.find(({ id }) => id === categoryId)?.name,
            total_products: total,
            user_merch_count: myDesigns.length,
          });

          setImmediate(() => {
            if (highlightedDesignRef.current) {
              highlightedDesignRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
            }
          });

          setLoadingNextPage(false);
        }
      );
    };

    loadInitialData();
  }, []);

  useEffect(() => {
    if (!maxItems && currentPage === 1) {
      return;
    }

    if (!maxItems) {
      const searchParams = new URLSearchParams();

      searchParams.append('page', `${currentPage}`);
      searchParams.append('categoryId', `${categoryId}`);

      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }

    setLoadingNextPage(true);

    getDesigns({
      isPublished: true,
      categoryId,
      take: maxItems || itemsPerPage,
      skip: (currentPage - 1) * itemsPerPage,
    }).then(({ designs, total }) => {
      setDesigns((oldDesigns) => (maxItems ? designs : uniqBy([...oldDesigns, ...designs], 'id')));

      setTotal(total);

      if (maxItems || designs.length < itemsPerPage) {
        setHasAllItems(true);
      }

      setLoadingNextPage(false);
    });
  }, [currentPage, maxItems, categoryId]);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoadingNextPage || hasAllItems) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoadingNextPage, hasAllItems]
  );

  const handleShowMyDesigns = (isChecked) => {
    setIsShowingMyDesigns(isChecked);
  };

  return (
    <Flex direction="column" h="100%" w="100%">
      <Show below="md">
        <Search value={searchTerm} onChange={setSearchTerm} width="100%" />
      </Show>
      {!maxItems ? (
        <Flex
          align="center"
          justify="space-between"
          mb={{ base: '12px', md: '20px' }}
          mt={{ base: '18px', md: 0 }}
        >
          <HStack spacing="27px">
            <Text fontWeight={600} textStyle="body">
              {`Products (${total})`}
            </Text>
            {user ? (
              <Checkbox
                isChecked={isShowingMyDesigns}
                onChange={(e) => handleShowMyDesigns(e.target.checked)}
              >
                <Text textStyle="body">My merch ({myDesigns.length})</Text>
              </Checkbox>
            ) : null}
          </HStack>
          <HStack>
            <Show above="md">
              <Search value={searchTerm} onChange={setSearchTerm} />
            </Show>
            <CardsDisplayToggle isLargeCardView={isLargeCardView} onChange={setLargeCardView} />
          </HStack>
        </Flex>
      ) : null}
      {!isShowingMyDesigns && !isLoadingNextPage && isEmpty(designs) ? (
        <EmptyState searchTerm={searchTerm} />
      ) : (
        <SimpleGrid
          columns={{ sm: isLargeCardView ? 1 : 2, md: isLargeCardView ? 4 : 6 }}
          spacing="12px"
          w="100%"
        >
          {[...designs, ...myDesigns].map((design, index) => {
            const { isMyDesign } = design as MyDesign;

            return (
              <Box
                key={design.id + index}
                display={
                  (isMyDesign && isShowingMyDesigns) || (!isMyDesign && !isShowingMyDesigns)
                    ? 'block'
                    : 'none'
                }
                mb="12px"
                ref={
                  index === designs.length - 1
                    ? lastElementRef
                    : design.id === highlightedDesignId
                    ? highlightedDesignRef
                    : null
                }
              >
                <ProductCard categories={categories} design={design} isLarge={isLargeCardView} />
              </Box>
            );
          })}
        </SimpleGrid>
      )}
      {isLoadingNextPage ? <LoadingSpinner mt="16px" /> : null}
    </Flex>
  );
}
