import { getCategories } from '@/api/templates';
import { Category, User } from '@/components/types';

import { Box, Center, Flex, Show } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { findIndex } from 'lodash';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { H2 } from '@/components/typography/Headings';
import CategoryTabs from './components/CategoryTabs';
import ProductList from './components/ProductList';
import { trackEvent } from '@/analytics';

const ALL_OPTION = {
  name: 'All',
  id: null,
};

interface ProductsListProps {
  me?: User;
}

const ProductsPage = ({ me }: ProductsListProps) => {
  const [categories, setCategories] = useState<Category[]>([]);

  const [loading, setLoading] = useState(true);

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(null);

  const location = useLocation();

  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);

  const defaultCategoryId = searchParams.get('categoryId');

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const orderedCategories = await getCategories();

        setCategories(orderedCategories);

        const allCategoryOptions = [ALL_OPTION, ...orderedCategories];

        const defaultCategoryIndex = Math.max(
          findIndex(allCategoryOptions, ({ id }) => defaultCategoryId === id),
          0
        );

        setSelectedCategoryId(allCategoryOptions[defaultCategoryIndex].id);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
  }, []);

  const handleSelectedCategory = (id) => {
    const searchParams = new URLSearchParams();

    searchParams.append('categoryId', `${id}`);

    trackEvent('category_tab_click', {
      selected_tab: categories.find((c) => c.id === id)?.name,
      previous_tab: categories.find((c) => c.id === selectedCategoryId)?.name,
    });

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    setSelectedCategoryId(id);
  };

  return (
    <Flex
      h="100%"
      bg="#FFFFFF"
      direction="column"
      padding={{ base: '16px 16px 80px 16px', md: '24px 32px' }}
    >
      <Show above="md">
        <H2 mb="24px">Limited merch - make it yours today!</H2>
      </Show>
      {loading ? (
        <Center bg="transparent" h="100%">
          <LoadingSpinner />
        </Center>
      ) : (
        <Flex direction="column" flex={1} overflow="auto">
          <CategoryTabs
            tabs={[ALL_OPTION, ...categories]}
            selectedValue={selectedCategoryId}
            onSelectedValue={handleSelectedCategory}
          />
          <Box mb={{ base: '12px', md: '24px' }} />
          <ProductList categoryId={selectedCategoryId} key={selectedCategoryId} user={me} />
        </Flex>
      )}
    </Flex>
  );
};

export default ProductsPage;
