import { useEffect } from 'react';
import AuthContainerModal from '../components/AuthContainerModal';

import SignInForm from './SignInForm';
import { trackEvent } from '@/analytics';

interface SignInModalProps {
  onClose: () => void;
  onGoToSignUp: () => void;
  onSignedIn: () => void;
  pageName: string;
}

export default function SignInModal({
  onGoToSignUp,
  onSignedIn,
  pageName,
  ...rest
}: SignInModalProps) {
  useEffect(() => {
    trackEvent('sign_in_opened', {
      source: 'Top Nav Sign-In Button',
      page_name: pageName,
      user_status: 'Guest',
    });
  }, []);

  return (
    <AuthContainerModal title="Sign In" {...rest}>
      <SignInForm isInModal onGoToSignUp={onGoToSignUp} onSignedIn={onSignedIn} />
    </AuthContainerModal>
  );
}
