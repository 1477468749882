import { IFontMakerRequest } from '@space-runners/ablo-ts-sdk/lib/services/font-maker/font-maker-request.interface';
import { IImageFileToImageRequest } from '@space-runners/ablo-ts-sdk/lib/services/photo-transformer/image-file-to-image-request.interface';
import { IImageUrlToImageRequest } from '@space-runners/ablo-ts-sdk/lib/services/photo-transformer/image-url-to-image-request.interface';
import { IStyle } from '@space-runners/ablo-ts-sdk/lib/services/style/style.interface';
import { Brand } from '../../components/types';
import { IImageMakerRequest } from '@space-runners/ablo-ts-sdk';

export interface User {
  avatarUrl?: string;
  email?: string;
  id: string;
  firstName: string;
  lastName: string;
}

export interface Keyword {
  id: string;
  name: string;
}

export interface CustomStyle extends IStyle {
  trainingStatus: string;
}

export interface Filters {
  collections?: string[];
  genders?: string[];
  sizes?: number[];
  colors?: string[];
  tags?: string[];
  fits?: string[];
  productTypes?: string[];
  brands?: string[];
  price?: [number, number];
}

export interface Garment {
  templateId: string;
  templateColorId: string;
  sizeId: number;
}

export interface TemplateColorImage {
  dataUrl?: string;
  templateSideId?: string;
  url?: string;
}

export interface TemplateColorSize {
  id?: string;
  sizeId: number;
  manufacturingVariantId?: string;
  storefrontVariantId?: string;
}

export interface TemplateColor {
  id?: string;
  name: string;
  hex: string;
  stitchColor?: string;
  order?: number;
  images?: TemplateColorImage[];
  sizes?: TemplateColorSize[];
  isDeleted?: boolean;
  previewImages?: TemplatePreviewImage[];
}

export interface TemplateSide {
  id?: string;
  left: number;
  top: number;
  height: number;
  order?: number;
  name: string;
  manufacturingAreaHeight?: number;
  manufacturingAreaWidth?: number;
  manufacturingImageLeft?: number;
  manufacturingImageTop?: number;
  manufacturingImageHeight?: number;
  manufacturingImageWidth?: number;
  initialCanvasState?: string;
  initialCanvasStateUrl?: string;
  width: number;
  previewImage?: string;
  tags?: string[];
}

export interface TemplateSize {
  id: number;
  name: string;
}

export interface TemplatePreviewImage {
  id?: string;
  image?: string;
  url?: string;
  order: number;
}

export interface Template {
  categoryId?: string;
  colors?: TemplateColor[];
  currency?: {
    id: number;
    name: string;
  };
  currencyId?: number;
  fabric?: string;
  brand?: string;
  brands?: Brand[];
  gender?: string;
  id?: string;
  madeIn?: string;
  name?: string;
  fit?: string;
  description?: string;
  price?: number | string;
  material?: string;
  sides?: TemplateSide[];
  collectionBannerImage?: string;
  collectionBannerImageMobile?: string;
  collectionBannerImageWide?: string;
  previewImage?: string;
  previewImages?: TemplatePreviewImage[];
  artboardIconUrl?: string;
  artboardCategory?: string;
  artboardSizeDescription?: string;
  defaultZoom?: number;
}

export interface AiImage {
  options?:
    | IImageMakerRequest
    | IImageUrlToImageRequest
    | IImageFileToImageRequest
    | IFontMakerRequest;
  url: string;
  noBackgroundUrl?: string;
  withBackgroundUrl?: string;
}

export interface CanvasState {
  canvas?: string;
  canvasUrl?: string;
}

export interface DesignSide {
  id?: string;
  canvas?: Canvas;
  templateSideId?: string;
  templateSide?: TemplateSide;
  hasGraphics?: boolean;
  hasText?: boolean;
  canvasState?: string;
  canvasStateUrl?: string;
  designImage?: string;
  previewImage?: string;
  manufacturingImageUrl?: string;
}

export interface MockupImage {
  id?: string;
  url?: string;
}
export interface Design {
  id?: string;
  isPublished?: boolean;
  name?: string;
  description?: string;
  previewImage?: string;
  designImage?: string;
  numLikes?: number;
  template?: Template;
  templateId: string;
  templateColorId: string;
  sizeId?: number;
  sides: DesignSide[];
  createdAt?: string;
  updatedAt?: string;
  userId?: string;
  acmeMintingLink?: string;
  user?: User;
  numCopies?: number;
  numPurchases?: number;
  totalEarnings?: number;
  mockupImages?: MockupImage[];
}

export interface CanvasObject {
  angle: number;
  aiImage?: AiImage;
  clear: () => void;
  clone: (callback: (clone: CanvasObject) => void, additionalProperties?: string[]) => void;
  getBoundingRect: () => {
    width: number;
  };
  left: number;
  set: (key: string, property: number | string) => void | ((properties: object) => void);
  text?: string;
  top: number;
  clipPath?: object;
  noBackgroundUrl?: string;
  opacity?: number;
  withBackgroundUrl?: string;
  fill?: string;
  fontFamily?: string;
  fontSize?: number;
  textAlign?: string;
  meta?: {
    id: string;
  };
  height?: number;
  width?: number;
  scaleToWidth: (width: number) => void;
  scaleX: number;
  scaleY: number;
  type: string;
  visible: boolean;
  _objects?: CanvasObject[];
  fromSVG?: boolean;
  src?: string;
  on?: (eventName: string, callback: () => void) => void;
  off?: (eventName: string) => void;
  freeDrawingBrush?: CanvasObject;
  editable?: boolean;
}

export interface CanvasEvent {
  ctx: object;
  target: CanvasObject;
}

export interface ClipPath {
  left: number;
  top: number;
  width: number;
  height: number;
}

export interface Canvas {
  add: (object: object) => Canvas;
  aiImage: AiImage;
  remove: (object: CanvasObject) => void;
  freeDrawingBrush: {
    width: number;
  };
  contextContainer: string;
  isDrawingMode: boolean;
  getActiveObject: () => CanvasObject;
  setActiveObject: (object: CanvasObject) => void;
  getAbsoluteCoords: (object: CanvasObject) => { left: number; top: number };
  centerObject: (object: CanvasObject) => void;
  on: (event: string, callback: (e: CanvasEvent) => void) => void;
  sendBackwards: (object: CanvasObject) => void;
  bringForward: (object: CanvasObject) => void;
  sendToBack: (object: CanvasObject) => void;
  width: number;
  height: number;
  _objects: [CanvasObject];
  objects: [CanvasObject];
  renderAll: () => void;
  toDataURL: (object: object) => string;
  clipPath: {
    left: number;
    top: number;
    width: number;
    height: number;
  };
  backgroundImage: string;
  overlayImage: string;
}

export interface AbloImage {
  id: string;
  url: string;
  options?: IImageMakerRequest | IImageFileToImageRequest | IFontMakerRequest;
}

export interface TextToImageResponse {
  images: AbloImage[];
  riskScore?: number;
}

export interface Favorite {
  id?: string;
  userId?: string;
  imageId: string;
  image?: AbloImage;
}

export enum HttpStatusCode {
  PaymentRequired = 402,
}
