import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Design } from '@/lib/types';

import { getFormattedAmount } from '../admin/utils/currency-formatter';
import { CartItem, TemplateColorSize } from '@/components/types';
import DesignSideImage from '../demo/pages/design/components/DesignSideImage';
import { ReactNode } from 'react';

const THUMBNAIL_SIZE = 85;

interface CartItemSummaryProps {
  design: Design;
  item: CartItem;
  templateColorSize: TemplateColorSize;
  quantity: number;
  quantityControl?: ReactNode;
}

const CartItemSummary = ({
  design,
  item,
  templateColorSize,
  quantity,
  quantityControl,
}: CartItemSummaryProps) => {
  const { name, template, sides } = design;

  return (
    <HStack align="center" spacing="12px">
      <DesignSideImage
        sideName={sides[0].templateSide.name}
        height={THUMBNAIL_SIZE}
        width={THUMBNAIL_SIZE}
        imageWidth={69}
        design={design}
      />
      <VStack align="flex-start" spacing={0} w="100%">
        <Text textAlign="left" fontWeight={600} textStyle="caption" pr="16px">
          {template.name} | {name}
        </Text>
        <Text color="secondaryDarkGray.600" mb="7px" textStyle="overline">
          Size: {templateColorSize?.size?.name || '-'} | Quantity: {quantity}
        </Text>
        <Flex alignItems="center" justify="space-between" w="100%">
          <Text textStyle="caption" fontWeight={600}>
            {getFormattedAmount(template.currency?.name, quantity * item.price)}
          </Text>
          {quantityControl}
        </Flex>
      </VStack>
    </HStack>
  );
};
export default CartItemSummary;
