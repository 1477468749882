import { useEffect } from 'react';
import AuthContainerModal from '../components/AuthContainerModal';

import SignUpForm from './SignUpForm';
import { trackEvent } from '@/analytics';

interface SignUpModalProps {
  onClose: () => void;
  onGoToSignIn: () => void;
  onSignedIn: () => void;
  onSignedUp?: () => void;
  pageName: string;
}

export default function SignUpModal({
  onGoToSignIn,
  onSignedIn,
  onSignedUp,
  pageName,
  ...rest
}: SignUpModalProps) {
  useEffect(() => {
    trackEvent('sign_up_opened', {
      source: 'Sign-In Modal',
      page_name: pageName,
      user_status: 'Guest',
    });
  }, []);

  return (
    <AuthContainerModal title="Welcome to Ablo!" {...rest}>
      <SignUpForm onGoToSignIn={onGoToSignIn} onSignedIn={onSignedIn} onSignedUp={onSignedUp} />
    </AuthContainerModal>
  );
}
