import GuestPageContainer from '@/layouts/GuestPageContainer';

import ProductsPage from './ProductsPage';

export default function ProductsPageGuest() {
  const handleSignedIn = () => {
    window.location.href = '/products';
  };

  return (
    <GuestPageContainer onSignedIn={handleSignedIn} pageName="Merch Shop">
      <ProductsPage />
    </GuestPageContainer>
  );
}
