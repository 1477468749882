import { Box, Image, Stack, Text, VStack } from '@chakra-ui/react';
import { H1 } from '../../../components/typography/Headings';
import CrocsBannerImage from './CrocsBanner.png';
import CrocsLogo from './CrocsLogo.png';
import Button from '../../../components/button';

interface CrocsBannerProps {
  onStartDesigning: () => void;
  onExploreCollection: () => void;
}

export const CrocsBanner = ({ onStartDesigning, onExploreCollection }: CrocsBannerProps) => {
  return (
    <VStack
      w="100%"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgImage={CrocsBannerImage}
      borderRadius={{ base: '0px', md: '12px' }}
      align="flex-start"
    >
      <Box
        backgroundBlendMode="multiply"
        bg={'linear-gradient(90deg, rgba(20, 62, 230, 0.12) 0%, rgba(255, 255, 255, 0) 100%)'}
        h="100%"
        pb="40px"
        pt="140px"
        px={{ base: '27px', md: '40px' }}
        w="100%"
      >
        <VStack align="flex-start" spacing="16px" w={{ base: '100%', md: '511px' }}>
          <Image src={CrocsLogo} h="16px" />
          <H1 color="#FFFFFF">Design Your Own Jibbitz™</H1>
          <Text color="#FFFFFF">
            Use AI to customize your Crocs Jibbitz™. Turn your photos into amazing graphics and
            start expressing your unique style. Just a few easy steps and you’ll be on your way.
          </Text>
          <Stack direction={{ base: 'column', '2sm': 'row' }} w={{ base: '100%', lg: '416px' }}>
            <Button
              flex={{ base: 'auto', md: 1 }}
              label="Design"
              onClick={onStartDesigning}
              target="Generate Art"
            >
              Design
            </Button>
            <Button
              flex={{ base: 'auto', md: 1 }}
              label="Explore Collection"
              onClick={onExploreCollection}
              secondary
              target="Merch Shop"
            >
              Explore Collection
            </Button>
          </Stack>
        </VStack>
      </Box>
    </VStack>
  );
};
