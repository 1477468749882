import { HStack, Text, VStack } from '@chakra-ui/react';

import Button from '@/components/button';
import { Design } from '@/lib';

import ModalContainer from '@/components/modals/ModalContainer';
import { H3 } from '../typography/Headings';
import { IconCheck } from '../icons/IconCheck';
import Config from '../../config';
import { trackEvent } from '@/analytics';
import { useCategories } from '@/api/templates';

type Props = {
  design: Design;
  onClose: () => void;
};

export const CrocsModal = ({ design, onClose }: Props) => {
  const { data: categories = [] } = useCategories();

  const handleGoToCrocs = () => {
    const redirectUrl = `${Config.CROCS_URL}?img_url=${design.sides[0].manufacturingImageUrl}`;

    trackEvent('shop_on_crocs_confirm', {
      product_id: design.id,
      product_name: design.name,
      price: design.template.price,
      category: categories.find(({ id }) => id === design.template.categoryId)?.name,
      designer_name: `${design.user.firstName} ${design.user.lastName}`,
      redirect_url: redirectUrl,
    });

    window.open(redirectUrl, '_blank');
  };

  return (
    <ModalContainer
      onClose={onClose}
      width={{ base: 'calc(100% - 20px)', md: 677 }}
      isCloseButtonVisible={false}
    >
      <VStack spacing="10px" pt="96px" pb="96px" textAlign="center">
        <HStack justify="center">
          <IconCheck />
        </HStack>
        <H3>Looking good!</H3>
        <Text
          color="secondaryDarkGray.600"
          mb="18px"
          textStyle="body"
          w={{ base: 'calc(100% - 20px)', md: 340 }}
        >
          You will be redirected to Crocs.com to finalize your Jibbitz design and checkout.
        </Text>

        <Button onClick={handleGoToCrocs} w="289px">
          Go to Crocs.com
        </Button>
      </VStack>
    </ModalContainer>
  );
};
