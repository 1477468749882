import { Box, Flex, Text } from '@chakra-ui/react';

import { Design } from '@/lib/types';

import CreatorAvatar from '@/views/home/components/CreatorAvatar';

import ProductCardContainer from './ProductCardContainer';
import { Category } from '@/components/types';

type ProductCardProps = {
  design: Design;
  isLarge?: boolean;
  categories?: Category[];
};

const ProductCard = ({ design, isLarge = true, categories = [] }: ProductCardProps) => {
  const { name, template, user } = design;

  return (
    <ProductCardContainer categories={categories} design={design} isLarge={isLarge}>
      <Box px="4px">
        <Flex justify="space-between" mb="4px" w="100%">
          <Text
            fontWeight={600}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            maxW="100%"
            textStyle="body"
          >
            {name}
          </Text>
          <Text fontWeight={600} textStyle="body">
            ${template.price}
          </Text>
        </Flex>
        <CreatorAvatar user={user} />
      </Box>
    </ProductCardContainer>
  );
};

export default ProductCard;
